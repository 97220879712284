






































import Vue from 'vue'
import { mapState } from 'vuex'
import { Component } from 'vue-property-decorator'

@Component({
  computed: mapState(['isDarkMode']),
})
export default class TransmuteDialog extends Vue {
  public isDarkMode!: boolean
  public visible: boolean = false

  public created() {
    this.$bus.$on('show-transmute-dialog', () => this.visible = true)
    this.$bus.$on('update-grade-transmute', (payload: any) => {
      const {id, newGrade} = payload
      this.$set(this.grades, id, newGrade)
    })
  }

  public compute(prevGrade: number, currGrade: number) {
    const prev = prevGrade / 3          // Previous grade makes 1/3 of final
    const curr = (currGrade * 2) / 3    // Current grade makes 2/3 of final
    const final = prev + curr

    if (final > 3) {
      // There are no decimal steps above 3.00, so we round to whole integers
      // We also round half down (i.e. 0.5 becomes 0)
      return -Math.round(-final)
    } else {
      // Round to nearest 0.25
      return Math.round(final * 4) / 4
    }
  }

  get grades(): number[] {
    return this.$store.getters.transmuteGrades
  }

  get finalGrade(): number {
    return this.compute(this.grades[0], this.grades[1])
  }
}
